// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ExploreIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ExploreIcon(props: ExploreIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M7.5 10a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm5-10C10.6 0 9 1.6 9 3.5S10.6 7 12.5 7 16 5.4 16 3.5 14.4 0 12.5 0z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M13 9c-.2 2.8-2.6 5-5.5 5-3 0-5.5-2.5-5.5-5.5C2 5.6 4.2 3.3 7 3c.1-.7.3-1.4.6-2h-.1C3.4 1 0 4.4 0 8.5S3.4 16 7.5 16 15 12.6 15 8.5v-.1c-.6.3-1.3.5-2 .6z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default ExploreIcon;
/* prettier-ignore-end */
