import * as React from "react";
import {  DatePicker, ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import de from 'dayjs/locale/de';
import en from 'dayjs/locale/en';
import fr from 'dayjs/locale/fr';
import it from 'dayjs/locale/it';
import DE from 'antd/locale/de_DE';
import EN from 'antd/locale/en_US';
import FR from 'antd/locale/fr_FR';
import IT from 'antd/locale/it_IT';
import {
  // PlasmicDateSetter,
  DefaultDateSetterProps
} from "./plasmic/imbas_23_fpre/PlasmicDateSetter";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import dayjs, { Dayjs } from "dayjs";
import { useFormContext } from "react-hook-form";
import { Locale } from 'antd/es/locale';
import "./DateSetterStyles.css";
import imbasConstants from "../Constants";


export interface DateSetterProps extends DefaultDateSetterProps {
  name: string;
  value: string;
}

function DateSetter_(
  { value, ...props }: DateSetterProps, 
  ref: HTMLElementRefOf<"div">
) {
  const getLocale = (language: string): Locale => {
  switch (language) {
      case "de-CH":
      case "de-DE":
        dayjs.locale(de);
        return DE;
      case "en-US":
        dayjs.locale(en);
        return EN;
      case "fr-CH":
      case "fr-LU":
        dayjs.locale(fr);
        return FR;
      case "it-CH":
        dayjs.locale(it);
        return IT;
      default:
        dayjs.locale(de);
        return DE;
  }
};

const { i18n } = useTranslation();
getLocale(i18n.language);
const { setValue } = useFormContext(); 


const handleDatePickerChange = (date:Dayjs, dateString:string | string[] | undefined) :void => {
  if (date === null) {
  setValue(props.name, null);} 
  else {
    const dateOnly = date.format("YYYY-MM-DD");
  setValue(props.name, dateOnly);}
}


  return (
    <ConfigProvider locale={getLocale(i18n.language)}>
     <DatePicker 
     {...props} 
     format={imbasConstants.dateFormat} 
     onChange={handleDatePickerChange} 
     value={value ? dayjs(value): null}
     className="custom-date-picker"
    />
    </ConfigProvider>
  );
}

const DateSetter = React.forwardRef(DateSetter_);
export default DateSetter;